import { DeviceNotification } from '@app/models/device';
import { DeviceNotificationsService } from '@services/device-notifications/device-notifications.service';
import { Injectable, inject } from '@angular/core';
import { LocalNotification } from '@app/interfaces/local-notification.interface';
import { LocalNotifications, ScheduleResult } from '@capacitor/local-notifications';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocalNotificationsService {
  private readonly deviceNotificationsService: DeviceNotificationsService = inject(DeviceNotificationsService);
  private readonly translate: TranslateService = inject(TranslateService);

  public initialize(): void {
    this.deviceNotificationsService.notifications
      .pipe(
        filter((notification: DeviceNotification | null): notification is DeviceNotification => notification !== null),
        map(notification => notification as DeviceNotification)
      )
      .subscribe(async (notification: DeviceNotification) => {
        if (notification !== null && notification !== undefined) {
          const title = `labels_notifications_${notification.type.toLowerCase()}_title`;
          const body = `labels_notifications_${notification.type.toLowerCase()}_description`;
          await this.scheduleNotification({ title, body });
        }
      });
  }

  /*
   * Used to determine if local notifications are enabled.
   */
  public async checkPermissions(): Promise<boolean> {
    return (await LocalNotifications.checkPermissions()).display === 'granted' ? true : false;
  }

  /*
   * Used to request the notifications permission.
   */
  public async requestPermissions(): Promise<void> {
    await LocalNotifications.requestPermissions();
  }

  /*
   * Used to schedule a local notification.
   */
  public async scheduleNotification(notification: LocalNotification): Promise<ScheduleResult> {
    return await LocalNotifications.schedule({
      notifications: [
        {
          title: this.translate.instant(notification.title),
          body: this.translate.instant(notification.body),
          id: Math.floor(Math.random() * 1000000000),
          schedule: {
            at: notification.date || new Date(Date.now() + 1000 * 5),
            allowWhileIdle: notification.allowWhileIdle || false
          }
        }
      ]
    });
  }

  /*
   * Used to cancel a local notification.
   */
  public async cancelNotification(id: number): Promise<void> {
    await LocalNotifications.cancel({ notifications: [{ id }] });
  }
}
